<template>
	<div class="all-rule-list">
		<el-form :model="dataForm" @keyup.enter.native="getDataList()">
			<el-form-item>
				<el-input style="width: 300px;" v-model="name" placeholder="输入病种名称/药品名称/简称关键字查询" clearable>
					<el-button slot="append" icon="el-icon-search" @click="getDataList()"></el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="gotoAdd()">添加</el-button>
				<!-- 				<el-button  type="primary"  @click="test()" >新增</el-button> -->
				<!-- <el-button type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" >删除</el-button> -->
			</el-form-item>
		</el-form>
		<el-table
			:data="dataList"
			:header-cell-style="{background:'#FAFAFA'}"
			v-loading="dataListLoading"
			@selection-change="selectionChangeHandle"
			style="width: 100%;"
		>
			<el-table-column type="selection" header-align="left" align="left" width="50"></el-table-column>
			<el-table-column header-align="left" align="left" min-width="280" label="药品简称">
				<template slot-scope="scope">
					<el-button
						style="color: #A767FD;"
						type="text"
						size="small"
						@click="gotoDetail(scope.row)"
					>{{scope.row.drugsSimpleName}}</el-button>
				</template>
			</el-table-column>
			<el-table-column
				prop="drugsFullName"
				min-width="200"
				header-align="left"
				align="left"
				label="药品全称"
			></el-table-column>
			<el-table-column header-align="left" min-width="200" align="left" label="药品简介">
				<template slot-scope="scope">
					<div class="ypjj">{{scope.row.drugsIntroduce}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="drugsUseTypeName" header-align="left" width="200" align="left" label="用法"></el-table-column>
			<el-table-column
				prop="drugsDefaultRate"
				header-align="left"
				align="left"
				width="180"
				label="基础剂量"
			></el-table-column>
			<el-table-column prop="drugsUseUnitName" header-align="left" align="left" width="180" label="单位"></el-table-column>
			<el-table-column header-align="left" align="left" width="180" label="频次">
				<template slot-scope="scope">
					<span>每{{scope.row.drugsDefaultRateName}}{{scope.row.drugsIntervalNum}}次</span>
				</template>
			</el-table-column>
			<el-table-column prop="drugsProductFirm" header-align="left" align="left" width="180" label="厂商"></el-table-column>
			<el-table-column fixed="right" header-align="center" align="center" width="200" label="操作">
				<template slot-scope="scope">
					<el-button style="color: #A767FD;" type="text" size="small" @click="gotoEdit(scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@size-change="sizeChangeHandle"
			@current-change="currentChangeHandle"
			:current-page="pageIndex"
			:page-sizes="[10, 20, 50, 100]"
			:page-size="pageSize"
			:total="totalPage"
			layout="total, sizes, prev, pager, next, jumper"
		></el-pagination>
		<el-dialog title="添加团队" :visible.sync="dialogVisible" width="500px">
			<el-form label-width="100px">
				<el-form-item :inline="true" label="团队名称" required>
					<el-input style="width:300px" v-model="drugsSimpleName" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="管理病种" required>
					<el-select style="width:300px" v-model="entityId" filterable>
						<el-option v-for="item in jcbzList" :key="item.value" :value="item.value" :label="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="团队介绍">
					<el-input
						:rows="4"
						style="width:300px"
						type="textarea"
						maxlength="90"
						v-model="content"
						placeholder="请输入"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addTeam" v-points>确定</el-button>
				<el-button @click="dialogVisible = false">取消</el-button>
			</div>
		</el-dialog>
		<el-dialog title="编辑团队" :visible.sync="dialogVisible2" width="500px">
			<el-form label-width="100px">
				<el-form-item :inline="true" label="团队名称" required>
					<el-input style="width:300px" v-model="dataForm.drugsSimpleName" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="管理病种" required>
					<el-select style="width:300px" v-model="dataForm.entityId" filterable>
						<el-option v-for="item in jcbzList" :key="item.value" :value="item.value" :label="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="团队介绍">
					<el-input
						:rows="4"
						style="width:300px"
						type="textarea"
						maxlength="90"
						v-model="dataForm.content"
						placeholder="请输入"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editTeam" v-points>确定</el-button>
				<el-button @click="dialogVisible2 = false">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Qs from "qs";
export default {
  components: {
    // AddOrUpdate
  },

  data() {
    return {
      drugsSimpleName: "",
      entityId: "",
      content: "",
      jcbzList: [],
      dataForm: {
        drugsSimpleName: "",
        entityId: "",
        content: "",
      },
      name: "",
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dialogVisible: false,
      dialogVisible2: false,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },

  computed: {},

  created() {
    this.getDataList();
    this.getJcbzList();
  },

  methods: {
    gotoEdit(row) {
      sessionStorage.medicineInfo = JSON.stringify(row);
      this.$router.push({
        name: "medicineEdit",
      });
    },
    gotoDetail(row) {
      sessionStorage.medicineInfo = JSON.stringify(row);
      this.$router.push({
        name: "medicineInfo",
      });
    },
    // 获取数据列表
    getDataList() {
      // let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
      let obj = {
        pageNo: this.pageIndex,
        pageSize: this.pageSize,
        drugsSimpleName: this.name,
        hospitalId: JSON.parse(localStorage.userInfo).deptPid,
        deptId: JSON.parse(localStorage.userInfo).deptId,
      };
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/drugsManage/list"),
        method: "get",
        params: obj,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.dataList = data.data;
        this.totalPage = data.totalCount;

        this.dataListLoading = false;
      });
    },
    gotoAdd() {
      this.$router.push("medicineAdd");
    },
    //基础病种
    getJcbzList() {
      let deptId = this.$cookie.get("hospitalId");
      // let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
      let obj = {
        pageNo: 1,
        pageSize: 9999,
        type: "基础病种",
      };
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/dict/list"),
        method: "get",
        params: obj,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.jcbzList = data.data;
      });
    },
    addTeam() {
      var that = this;
      var params = {
        drugsSimpleName: this.drugsSimpleName,
        content: this.content,
        hospitalId: JSON.parse(localStorage.userInfo).deptPid,
        deptId: JSON.parse(localStorage.userInfo).deptId,
        entityId: this.entityId,
      };
      that
        .$http({
          url: that.$http.adornUrl("/teamsManage/add"),
          method: "post",
          params: params,
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                that.dialogVisible = false;
                that.content = "";
                that.drugsSimpleName = "";
                that.entityId = "";
                that.getDataList();
              },
            });
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    editTeam() {
      var that = this;

      that
        .$http({
          url: that.$http.adornUrl("/teamsManage/update"),
          method: "post",
          params: that.dataForm,
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                that.dialogVisible2 = false;

                that.getDataList();
              },
            });
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },

    gotoRoleInfo(info) {
      this.$router.push({
        path: "/role/role-info",
        query: { id: info.id },
      });
    },
    gotoMemberEdit(info) {
      this.$router.push({
        path: "/team/memberList",
        query: {
          id: info.id,
          drugsSimpleName: info.drugsSimpleName,
        },
      });
    },

    // 删除
    deleteHandle(id) {
      var userIds = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      var ids = {
        userId: this.$cookie.get("userId"),
        ids: userIds.join(","),
      };

      this.$confirm(`确定进行[${id ? "删除" : "批量删除"}]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/drugsManage/delete"),
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",

              token: this.$cookie.get("token"),
            },
            data: Qs.stringify(ids),
          }).then(({ data }) => {
            if (data.status) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.all-rule-list {
  .search-top {
    padding-bottom: 20px;
    overflow: hidden;

    .search-btn {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .el-dialog {
    width: 600px;
  }
  .ypjj {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
}
</style>